import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@shared/services';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private service: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const clonedReq = request.clone({
      url: request.url,
      setHeaders: {
        Authorization: `Bearer ${this.service.token.get()}`,
      },
    });

    return next.handle(clonedReq);
  }
}
