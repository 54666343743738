import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from '@shared/interceptors';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { AuthService, SharedService } from '@shared/services';
import { SharedModules } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyMissingTranslationHandler } from './missingTranslationHandler';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeApp(authService: AuthService, sharedService: SharedService) {
  return async () => {
    authService.resolveLoginToken();
    await sharedService.initializeStore();
  };
}

@NgModule({
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService, SharedService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModules,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
