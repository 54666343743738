import { Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedStore } from '@shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  @ViewChild('content') appContent!: ElementRef;

  constructor(private tr: TranslateService, private shareStore: SharedStore) {

    const language = shareStore.user.get()['custom:language'];

    this.tr.setDefaultLang('en');
    this.tr.use(language);
  }

  toggleSideBar(opened: boolean) {
    this.appContent.nativeElement.style.marginLeft = opened ? '312px' : '92px';
  }
}
